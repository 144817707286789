import React from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'

const PrivacyPolicy = () => {
  return (
    <>
      <Seo
        title={'Cookie Policy | immence'}
        description={
          'With an innovative leading approach, we offer software development services like -Web Design &amp; Development, -Mobile Design &amp; Development, -ERP Consulting &amp; Support, -Corporate Branding and much more. Hurry! Consult your project for free!'
        }
      />
      <Layout>
        <div>
          <div>
            <section className="cms-heading com-section policySection">
              <div className="container">
                <div className="row">
                  <h1 className="hero-heading text-center">Privacy Policy</h1>
                  <p className="text-center whiteText">
                    Last Updated: 29th June 2022
                  </p>
                </div>
              </div>
            </section>
            <section className="com-section cms-page">
              <div className="container">
                <div className="row">
                  <p>
                    Your privacy is our foremost concern. At immence, we
                    understand how important privacy is for each one of us and
                    we ensure to preserve it by all means. This policy is
                    intended to describe how we capture, use and process our
                    clients’ and visitors’ personal data.
                  </p>
                  <p>
                    This privacy policy explains how, what, and why personal
                    information that you submit via forms on the website or we
                    collect from you is used and stored.
                  </p>
                  <p>
                    It is to be noted that by visiting and using our website,
                    www.immence.com, you accept our terms and conditions
                    mentioned in this privacy policy. Also, we keep updating our
                    policies so it is recommended to visit this page from time
                    to time to remain abreast with the latest terms and
                    conditions.{' '}
                  </p>
                  <p>
                    We collect the following data from your visit to our
                    website,
                  </p>
                  <ul>
                    <li>Pages viewed</li>
                    <li>Website resources you accessed on different pages</li>
                    <li>Communication data</li>
                    <li>Location data</li>
                    <li>Traffic data</li>
                  </ul>
                  <p>
                    Some of the data is received directly from you, voluntarily,
                    through communication modes set up on our website like
                    forms, direct email, and call options, whereas we gain
                    access to other information through communication you have
                    with our associates while you are on the website.{' '}
                  </p>
                  <p>
                    It is to be noted that the above-mentioned data does not
                    identify a user personally. It is just statistical data that
                    help us enhance our website and the services we offer.{' '}
                  </p>
                  <p>
                    <span className="blueText">
                      <b>Note:</b>
                    </span>{' '}
                    We do not save your financial information, credit/debit card
                    details, or bank account details during payment processing
                    for any kind of service you avail.{' '}
                  </p>
                  <h3 className="blueText">
                    <strong>Use of cookies</strong>
                  </h3>
                  <p>
                    Cookies help in identifying the computer a user uses. We may
                    use cookies to capture data about your computer and network
                    only to enhance our offerings and website. We do not collect
                    or store data that discloses the user's identity. The user's
                    privacy remains intact at all times.{' '}
                  </p>
                  <p>
                    You can choose to not save cookies from the settings on your
                    computer if you don’t want us to save statistical data.
                  </p>
                  <h3 className="blueText">
                    <strong>How do we use captured data?</strong>
                  </h3>
                  <p>
                    The primary objective of storing data is to provide the best
                    of our services by enhancing our website. You can check the
                    purposes of data gathered below:
                  </p>
                  <ul>
                    <li>To quickly provide you requested information</li>
                    <li>
                      To provide information related to similar products or
                      services
                    </li>
                    <li>
                      To update you about any changes to our service offerings,
                      website, and products
                    </li>
                    <li>
                      To provide you with the latest information about the
                      services or products you purchased from us before
                    </li>
                  </ul>
                  <p>
                    The information you provide may be shared with some selected
                    third parties whose products or services might be of your
                    interest, only if you provide consent for the same. You can
                    withdraw the consent any time you wish to.
                  </p>
                  <h3 className="blueText">
                    <strong>Personal information storage and security</strong>
                  </h3>
                  <p>
                    By filling up forms on our website you provide us with your
                    personal information and access to the same for future
                    references. We deploy all possible measures to protect your
                    personal information but we cannot guarantee the same as
                    sending information via the Internet is not fully secure.
                    Data can be intercepted and sharing any personal information
                    is completely at your own risk.{' '}
                  </p>
                  <h3 className="blueText">
                    <strong>Disclosing data</strong>
                  </h3>
                  <p>
                    We do not disclose our clients’ or visitors’ personal
                    information to third parties. We neither trade personal
                    information nor do we sell or rent it to third parties. We
                    may share generic aggregated information with our trusted
                    business partners, affiliates, and advertisers for marketing
                    purposes.
                  </p>
                  <p>
                    <span className="blueText">
                      <b>Note:</b>
                    </span>{' '}
                    Any information that is circulated within our trusted
                    business circle does not reveal anyone’s identity. Your
                    privacy remains preserved at all times.{' '}
                  </p>
                  <h3 className="blueText">
                    <strong>Third-party links on the website </strong>
                  </h3>
                  <p>
                    We may have third-party links on our website that redirect
                    to vendor websites that do not mean we endorse or approve
                    our website’s policy towards visitors’ privacy. It is
                    advised to review the privacy policy of the third-party
                    website before sharing personal information.
                  </p>
                  <h3 className="blueText">
                    <strong>Amendments to this policy</strong>
                  </h3>
                  <p>
                    We keep modifying this policy to ensure utmost security to
                    your data and details that you share with us from time to
                    time. We update the date on this page whenever we revise and
                    publish the modified policy. We encourage our website
                    visitors and clients to frequently check this page and stay
                    informed about the latest measures and amendments. We are
                    considering that you acknowledge and agree to review the
                    privacy policy periodically and stay informed about the
                    updates and modifications.
                  </p>
                  <h3 className="blueText">
                    <strong>Your acceptance of this privacy policy</strong>
                  </h3>
                  <p>
                    Your use of the website grants your acceptance of this
                    privacy policy. If you do not wish to give your acceptance
                    or do not agree to the terms mentioned in this policy, it is
                    advised that please do not use immence.com.
                  </p>
                  <p>
                    Your continued use of our website,{' '}
                    <a href="https://immence.com" target="_blank">
                      immence.com
                    </a>
                    , following the modifications to this privacy policy, will
                    be deemed as your acceptance of the new terms and
                    conditions.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
